// eslint-disable-next-line regex/invalid
import axios from "axios"

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL:
    import.meta.env.VITE_API_BASE_URL ||
    "__VITE_API_BASE_URL__" ||
    "http://localhost:5000",
  timeout: 5000,
  withCredentials: true,

  headers: { "Content-Type": "application/json" },
})

// ℹ️ Add request interceptor to send the authorization header on each subsequent request after login
/*axiosIns.interceptors.request.use(config => {
  // Retrieve token from localStorage
  const token = localStorage.getItem('accessToken')

  // If token is found
  if (token) {
    // Get request headers and if headers is undefined assign blank object
    config.headers = config.headers || {}

    // Set authorization header
    // ℹ️ JSON.parse will convert token to string
    config.headers.Authorization = token ? `Bearer ${JSON.parse(token)}` : ''
  }

  // Return modified config
  return config
})*/
export default axiosIns
