import { getI18n } from "@/plugins/i18n"
import { useI18n } from "vue-i18n"
import { createVuetify } from "vuetify"
import { VBtn } from "vuetify/components/VBtn"
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n"
import defaults from "./defaults"
import { icons } from "./icons"
import theme from "./theme"

// Styles
import "@core/scss/template/libs/vuetify/index.scss"
import "vuetify/styles"

export default createVuetify({
  locale: {
    adapter: createVueI18nAdapter({ i18n: getI18n(), useI18n }),
  },
  aliases: {
    IconBtn: VBtn,
  },
  defaults,
  icons,
  theme,
})
