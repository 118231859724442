import axiosIns from "@axios"
import dayjs from "dayjs"
import "dayjs/locale/de"
import "dayjs/locale/fr"
import FlatPickr from "flatpickr"
import FlatpickrLanguages from "flatpickr/dist/l10n"
import { nextTick } from "vue"
import VueCookies from "vue-cookies"
import { createI18n } from "vue-i18n"

let _i18n = null
export const SUPPORT_LOCALES = ["en", "fr", "de"]

export async function setupI18n(
  options = {
    legacy: false,
    locale: VueCookies.get("lang") || navigator.language.split("-")[0] || "en",
    fallbackLocale: "en",
  }
) {
  !SUPPORT_LOCALES.includes(options.locale) ? (options.locale = "en") : null
  _i18n = createI18n(options)
  options.locale !== "en"
    ? await loadLocaleMessages(options.fallbackLocale)
    : null
  await loadLocaleMessages(options.locale)
  setI18nLanguage(options.locale)
  return _i18n
}

export function setI18nLanguage(locale) {
  if (_i18n.mode === "legacy") {
    _i18n.global.locale = locale
  } else {
    _i18n.global.locale.value = locale
  }

  dayjs.locale(locale)
  VueCookies.set("lang", locale, "30d")
  FlatPickr.localize(FlatpickrLanguages[locale])
  axiosIns.defaults.headers["Accept-Language"] = locale
  document.documentElement.setAttribute("lang", locale)
}

export async function loadLocaleMessages(locale) {
  // if already loaded (messages.value.locale not empty) return nextTick

  if (
    _i18n.global.messages.value[locale] &&
    Object.keys(_i18n.global.messages.value[locale]).length > 0
  ) {
    return nextTick()
  }

  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  )

  // set locale and locale message
  _i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export function getI18n() {
  if (!_i18n) {
    setupI18n()
  }
  return _i18n
}
