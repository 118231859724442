<script setup>
  import posthogIns from "@/plugins/posthog"
  import axiosIns from "@axios"
  import { useListenersStore } from "@store/listeners"
  import { useUserStore } from "@store/user"
  import { useI18n } from "vue-i18n"

  const { t } = useI18n()
  const isCookieBannerVisible = ref(true)
  const $cookies = inject("$cookies")
  const analytics = ref(
    $cookies.get("cookie_consent")
      ? $cookies.get("cookie_consent").analytics
      : true
  )
  const ads = ref(
    $cookies.get("cookie_consent") ? $cookies.get("cookie_consent").ads : true
  )

  const userStore = useUserStore()
  const listenersStore = useListenersStore()

  const handleAccept = () => {
    isCookieBannerVisible.value = false
    posthogIns.opt_in_capturing()

    //❗ - should be secure but doesn't work for now
    $cookies.set(
      "cookie_consent",
      { initalConsent: true, analytics: analytics.value, ads: ads.value },
      "30d",
      import.meta.env.BASE_URL || "__BASE_URL__",
      false,
      "Lax"
    )

    listenersStore.onConsentChange()
    updateConsentIfLoggedIn(true)
  }

  const handleReject = () => {
    isCookieBannerVisible.value = false
    posthogIns.opt_out_capturing()

    $cookies.set(
      "cookie_consent",
      { initalConsent: false, analytics: false, ads: false },
      "30d",
      import.meta.env.BASE_URL || "__BASE_URL__",
      false,
      "Lax"
    )

    listenersStore.onConsentChange()
    updateConsentIfLoggedIn(false)
  }

  const updateConsentIfLoggedIn = async initalConsent => {
    if (userStore.userData.type && !userStore.userData.isNewUser) {
      await axiosIns.put("/user/consent", {
        consent: {
          initalConsent: initalConsent,
          analytics: initalConsent ? analytics.value : false,
          ads: initalConsent ? ads.value : false,
        },
      })
    }
  }

  onBeforeMount(() => {
    window.onConsentChanged = listenersStore.addConsentListener

    const cookieConsent = $cookies.get("cookie_consent")

    if (cookieConsent) {
      isCookieBannerVisible.value = false
    } else if (userStore.userData.consent) {
      $cookies.set(
        "cookie_consent",
        userStore.userData.consent,
        "30d",
        import.meta.env.BASE_URL || "__BASE_URL__",
        false,
        "Lax"
      )
      listenersStore.onConsentChange()
      isCookieBannerVisible.value = false
    }
    analytics && cookieConsent
      ? posthogIns.opt_in_capturing()
      : posthogIns.opt_out_capturing()
  })
</script>

<template>
  <VBanner
    v-if="isCookieBannerVisible"
    class="position-fixed bottom-0"
    icon="mdi-cookie"
    lines="two"
  >
    <VBannerText>
      <i18n-t keypath="Cookies.Banner.text" tag="span" scope="global">
        <span class="font-weight-bold">
          {{ t("Cookies.Banner.title") }} <br
        /></span>
        <RouterLink :to="{ name: 'cookie-policy' }" target="_blank">{{
          t("legal.cookie")
        }}</RouterLink>
      </i18n-t>
    </VBannerText>

    <template v-slot:actions>
      <div class="gap-4 d-flex">
        <VBtn @click="handleReject" color="secondary"> {{ t("Reject") }} </VBtn>
        <VBtn @click="handleAccept" variant="flat" color="primary">
          {{ t("Accept") }}
        </VBtn>
      </div>
    </template>
  </VBanner>
</template>

<style scoped>
  .bottom-0 {
    bottom: 0;
    z-index: 9999;
  }
</style>
